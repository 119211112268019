.service {
  margin: 2rem 0;
}

.service header {
  display: flex;
  align-items: center;
}

.service header h2 {
  margin: 0;
}

.service .information {
  margin-left: 0.5em;
  opacity: 0.5;
}

@media (max-width: 500px) {
  .service .information {
    font-size: 1rem;
  }
}

.service .emoji {
  font-size: 2em;
  margin-right: 0.5em;
}

.service .description {
  margin-top: 1em;
}

.service.service-alive .emoji {
  color: #2ecc40;
}

.service.service-slow .emoji {
  color: #ff851b;
}

.service.service-dead .emoji {
  color: #ff4136;
}

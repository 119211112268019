.notice {
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 0.25rem;
}

.notice-neutral {
  background-color: var(--bg-secondary);
  border: solid 1px var(--bg-tertiary);
}

.notice-green {
  background-color: hsl(120, 100%, 85%);
}

.notice-red {
  background-color: hsl(0, 100%, 85%);
}

@media (prefers-color-scheme: dark) {
  .notice-green {
    background-color: hsl(120, 50%, 20%);
    border: solid 1px hsl(120, 50%, 40%);
  }

  .notice-red {
    background-color: hsl(0, 50%, 20%);
    border: solid 1px hsl(0, 50%, 40%);
  }
}

.graph-container {
  width: 100%;
}

.recharts-cartesian-axis-tick-value {
  font-size: 0.85em;
}

.recharts-tooltip-item {
  padding: 0 !important;
}

.recharts-tooltip-wrapper {
  color: inherit;
}

.recharts-tooltip-wrapper .recharts-default-tooltip {
  border: none !important;
  padding: 0.5em !important;
  border-radius: 0.2rem;
  font-size: 0.85em;
  opacity: 0.75;
  line-height: 1.25;
  background-color: white;
}

@media (prefers-color-scheme: dark) {
  .recharts-tooltip-wrapper .recharts-default-tooltip {
    background-color: black !important;
  }
}

.recharts-default-tooltip li {
  color: inherit !important;
}

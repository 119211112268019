.settings {
  background: var(--bg);
  border: solid 1px var(--bg-secondary);
  box-shadow: 0 0 2rem hsla(0, 0%, 0%, 0.1);
  padding: 0.5rem;
  position: fixed;
  top: 1rem;
  right: 1rem;
  border-radius: 0.15rem;
  z-index: 10;
}

.settings .explanation {
  opacity: 0.75;
  margin: 0;
  font-size: 80%;
}

.control {
  margin: 0.5rem 0;
}

.control:first-child {
  margin-top: 0;
}

.control:last-child {
  margin-bottom: 0;
}

.control label {
  margin-right: 0.5em;
}

@media (prefers-color-scheme: dark) {
  .settings {
    background: var(--bg-tertiary);
    box-shadow: 0 0 2rem hsla(0, 0%, 0%, 0.5);
    border: solid 1px var(--bg-quaternary);
  }
}

.status {
  margin: 1rem 0;
  background-color: hsl(0, 0%, 50%);
  padding: 1em;
  border-radius: 0.25rem;
}

.status-bad {
  background-color: hsla(0, 100%, 75%, 0.5);
}

.status-good {
  background-color: hsla(120, 100%, 75%, 0.5);
}

.navbar {
  padding: 0.5rem 2rem;
  background-color: var(--bg-secondary);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.navbar h1 {
  font-size: 2rem;
  margin: 0;
}

.navbar .right {
  color: var(--fg-secondary);
  margin-left: auto;
}

.navbar button {
  background: var(--bg-secondary);
  border: solid 1px var(--bg-tertiary);
  padding: 0.25rem 0.5rem;
  color: inherit;
  border-radius: 0.25rem;
  margin-left: 0.5rem;
  font: inherit;
}

.incident h2 {
  margin-top: 0;
  margin-bottom: 1rem;
}

.incident p {
  margin: 1rem 0;
  hyphens: auto;
}

.incident footer {
  margin-top: 1rem;
  font-size: 0.75em;
  color: rgba(0, 0, 0, 0.5);
}

.incident .stage h3 {
  margin: 1rem 0;
}

.incident .stage {
  margin-top: 2rem;
}

@media (prefers-color-scheme: dark) {
  .incident footer {
    color: rgba(255, 255, 255, 0.5);
  }
}

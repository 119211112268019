:root {
  --fg: black;
  --fg-secondary: hsl(0, 0%, 10%);
  --bg: white;
  --bg-secondary: hsl(0, 0%, 90%);
  --bg-tertiary: hsl(0, 0%, 80%);
  --bg-quaternary: hsl(0, 0%, 70%);
}

html,
body {
  box-sizing: border-box;
  background-color: var(--bg);
  color: var(--fg);
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font: 16px/1.5 system-ui, sans-serif;
}

h1:first-child {
  margin-top: 0;
}

a {
  color: hsl(210, 70%, 60%);
}

@media (prefers-color-scheme: dark) {
  :root {
    --fg: hsl(0, 0%, 90%);
    --fg-secondary: hsl(0, 0%, 80%);
    --bg: black;
    --bg-secondary: hsl(0, 0%, 10%);
    --bg-tertiary: hsl(0, 0%, 20%);
    --bg-quaternary: hsl(0, 0%, 30%);
  }

  .show-loading-animation.round-shape,
  .show-loading-animation.rect-shape,
  .show-loading-animation .text-row {
    background-color: hsl(0, 0%, 20%) !important;
  }
}

.form-group {
  margin: 1rem 0;
}

.form-group input:not([type='submit']),
.form-group select {
  width: 100%;
  display: block;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--fg-secondary);
  font-weight: bold;
}

input[type='submit'],
button,
input,
select {
  font: inherit;
  background-color: var(--bg-secondary);
  border: solid 1px var(--bg-tertiary);
  border-radius: 0.15rem;
  color: var(--fg);
  padding: 0.25em 0.5em;
}

input[type='submit'],
button {
  padding: 0.5em 1em;
  cursor: pointer;
}

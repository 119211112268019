main.dashboard .notice {
  margin: 0;
  border-radius: 0;
  padding: 1rem 2rem;
  font-size: 1.25rem;
}

main.dashboard .service:first-child {
  margin-top: 0;
}

main.dashboard.grid-view .container {
  max-width: none;
  margin: 0;
  display: grid;
  padding: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-gap: 1rem;
}

main.dashboard.grid-view .service {
  margin: 0;
  display: flex;
  flex-flow: column nowrap;
}

main.dashboard.grid-view .service .graph-container {
  /* align graphs to bottom of service */
  margin-top: auto;
}
